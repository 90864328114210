import { useRouter } from 'next/router'

const tabElementSelectors = [
  'code.language-json',
  'label',
  'div.sc-iJnaPW',
  'div[style="display: block; overflow-x: auto; background: rgb(247, 247, 250); color: rgb(84, 84, 84); padding: 0.5em; font-size: 1rem;"]',
]

const markdownSvgSelector = '[class*="documentPageMDContent"] svg',
  markdownSvgAriaLabel = 'Mermaid SVG Image',
  searchInterval = 500,
  domSearchLimit = 20

let 
  addTabSearchInterval: any,
  svgSearchInterval: any,
  tabSearchIndex = 0,
  svgSearchIndex: any

function addTabIndexUpdates() {
  for (
    let elementSelectorIndex = 0;
    elementSelectorIndex < tabElementSelectors.length;
    elementSelectorIndex++
  ) {
    let htmlElements = document.querySelectorAll(
      tabElementSelectors[elementSelectorIndex]
    )

    for (
      let htmlElementsIndex = 0;
      htmlElementsIndex < htmlElements.length;
      htmlElementsIndex++
    ) {
      if (htmlElements.length > 0) {
        htmlElements[htmlElementsIndex].setAttribute('tabIndex', '0')
      }
    }
  }

  tabSearchIndex++

  if (tabSearchIndex > domSearchLimit) {
    clearInterval(addTabSearchInterval)
  }
}

function addAriaLabelToSvgUpdates() {
  let elements = document.querySelectorAll(markdownSvgSelector)

  for (let elementIndex = 0; elementIndex < elements.length; elementIndex++) {
    elements[elementIndex].setAttribute('aria-label', markdownSvgAriaLabel)
  }

  svgSearchIndex++

  if (svgSearchIndex > domSearchLimit) {
    clearInterval(svgSearchInterval)
  }
}

export const titleTagSuffix = 'Developer Portal'

export function addTabIndex() {
  tabSearchIndex = 0

  addTabSearchInterval = setInterval(addTabIndexUpdates, searchInterval)
}

export function addAriaLabelToSvg() {
  svgSearchIndex = 0

  svgSearchInterval = setInterval(addAriaLabelToSvgUpdates, searchInterval)
}

export function getTitleFromRoute() {
  let router = useRouter()

  switch (router.asPath) {
    case '/accept-terms-of-use':
      return `Accept terms of use | ${titleTagSuffix}`

    case '/profile':
      return `Profile | ${titleTagSuffix}`

    case '/support/create':
      return `Support | ${titleTagSuffix}`

    case '/credentials':
      return `Credentials | ${titleTagSuffix}`

    case '/forgot-username':
      return `Forgot username | ${titleTagSuffix}`

    case '/forgot_password':
      return `Forgot password | ${titleTagSuffix}`

      case '/profile/add-user':
        return `Add user | ${titleTagSuffix}`  

    default:
      return titleTagSuffix
  }
}
