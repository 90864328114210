import { useMutation } from '@tanstack/react-query'
import userService from './user-management.service'
import { useUserManagementCacheUtil } from './user-management-cache.util'
import { useRouter } from 'next/router'
import { MyUser, UpdatedUser } from '@/modules/UserManagement/components/UserList/userList.model'

export const useUserManagementMutation = () => {
  const router = useRouter()
  const userManagementCacheUtil = useUserManagementCacheUtil()

  const addUserMutation = useMutation({
    mutationFn: userService.addUser,
    onSuccess: (newUser: MyUser) => {
      userManagementCacheUtil.addUserInCache(newUser)
    },
  })

  const updateUserMutation = useMutation({
    mutationFn: userService.updateUser,
    onSuccess(updatedUser: UpdatedUser) {
      userManagementCacheUtil.updateUserInCache(updatedUser)
      userManagementCacheUtil.updateUserInListInCache(updatedUser)
    },
  })

  const softDeleteRequestMutation = useMutation({
    mutationFn: userService.softDeleteUserRequest,
    onSuccess(id: string) {
      userManagementCacheUtil.softDeleteUserRequestInCache(id)
    },
  })

  const denyAccessRequestMutation = useMutation({
    mutationFn: userService.denyeUserAccessRequest,
    onSuccess(data: any) {
      userManagementCacheUtil.denyAccessRequestInCache(data.data.deniedAccessRequestForOnboardingRequest.developerId)
    },
  })

  const addActivityLogMutation = useMutation({
    mutationFn: userService.addActivityLog,
    onSuccess: (data: any) => {
      userManagementCacheUtil.invalidateAddActivityLogInCache()
    },
  })

  return {
    addUserMutation,
    updateUserMutation,
    softDeleteRequestMutation,
    denyAccessRequestMutation,
    addActivityLogMutation
  }
}
