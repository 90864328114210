import axios, { AxiosResponse } from 'axios'
import { AddUserPageFormValues } from '../components/AddUserPage/helpers/addUserPage.model'
import { MyUser, UpdatedUser } from '@/modules/UserManagement/components/UserList/userList.model'
import { AddActivityLogReqDetails } from '@/modules/api/users/add-activity-log/model'

const fetchUsers = async (): Promise<MyUser[]> => {
  const headers = { 'content-type': 'application/json' }
  const res: AxiosResponse<any> = await axios.get('/api/users/fetch-users-list', {
    headers,
  })
  return res.data
}

const fetchUserById = async (): Promise<MyUser> => {
  const headers = { 'content-type': 'application/json' }
  const res: AxiosResponse<any> = await axios.get('/api/users/fetch-user-by-id', {
    headers,
  })
  return res.data
}
const fetchUsersByDomain = async (): Promise<any>  => {
  const headers = { 'content-type': 'application/json' }
  const res: AxiosResponse<any> = await axios.get('/api/users/fetch-users-list-by-domain', {
    headers,
  })
  return res.data
}


const fetchAllDevelopersList = async (): Promise<any>  => {
  const headers = { 'content-type': 'application/json' }
  const res: AxiosResponse<any> = await axios.get('/api/users/fetch-all-developers-list', {
    headers,
  })
  return res.data
}

const addUser = async ({
  formValues
}: {
  formValues: AddUserPageFormValues
}): Promise<any>  => {
  const query = JSON.stringify({
    email: formValues.emailAddress,
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    username: formValues.userName
  })
  const headers = { 'content-type': 'application/json' }

  const response: AxiosResponse<any> = await axios.post('/api/users/add-user', query, {
    headers,
  })
  return response
}
const updateUser = async ({
  developerId,
  firstName,
  lastName,
  mainContact,
  organizationId,
  actionType
}: {
  developerId: string
  firstName: string
  lastName: string
  mainContact: boolean
  organizationId: string
  actionType: string
}): Promise<UpdatedUser> => {
  const query = JSON.stringify({
    developerId: developerId,
    firstName: firstName,
    lastName: lastName,
    mainContact: mainContact,
    organizationId: organizationId,
  })

  const res: any = await axios.put(
    `/api/users/edit-user`,
    query,
    {
      headers: { 'content-type': 'application/json', 'actiontype': actionType },
    }
  )
  return res
}

const softDeleteUserRequest = async (id: string) => {
  const query = JSON.stringify({
    softDeleteUserId: id
  })
  const headers = { 'content-type': 'application/json' }
  const response: AxiosResponse<{id: string}>= await axios.post('/api/users/soft-delete', query, {
    headers,
  })
  return id
}

const denyeUserAccessRequest = async (id: string) => {
  const query = JSON.stringify({
    deniedUserId: id
  })
  const headers = { 'content-type': 'application/json' }
  const response: AxiosResponse<{id: string}>= await axios.post('/api/users/denied-access-request', query, {
    headers,
  })
  return response.data
}

const addActivityLog = async ({
  data
}: {
  data: AddActivityLogReqDetails
}): Promise<any>  => {
  const query = JSON.stringify({
    action: data.action,
    message: data.message,
    properties: data.properties
  })
  const headers = { 'content-type': 'application/json' }

  const response: AxiosResponse<any> = await axios.post('/api/users/add-activity-log', query, {
    headers,
  })
  return response
}

const userService = {
  fetchUsers,
  addUser,
  updateUser,
  softDeleteUserRequest,
  denyeUserAccessRequest,
  fetchUserById,
  fetchUsersByDomain,
  fetchAllDevelopersList,
  addActivityLog
}
export default userService

