import { USBGrid } from '@usb-shield/react-grid'
import '@usb-shield/react-grid/dist/library/styles/index.css'
import { defaultGridOptions } from '@/utils/usb-grid.util'
import styles from './pageHeader.module.scss'
import { MobileNavBar } from './MobileNavBar'
import { DesktopNavBar } from './DesktopNavBar'
import Head from 'next/head'
import { fetchProductVersions } from '@/utils/products/fetch-product-version-and-user-token'
import { useProductListQuery } from '@/modules/products/services/product-list.query'

const headerDefaultGridOptions = {
  ...defaultGridOptions,
  padding: 'zero',
}

export const PageHeader = (headerData: any) => {
  const type = headerData?.type
  const menuItems = headerData?.menuItems
  const imageURL = headerData?.imageURL
  const mainmenuId = headerData?.mainmenuId
  const fullWidthLayout = headerData?.fullWidthLayout || false
  const regionOptions = headerData?.regionOptions
  const pageTitle = headerData?.title ? headerData.title : false
  const { useProductVersionsListQuery } = useProductListQuery()
  const { data: productListData } = useProductVersionsListQuery()
  const allProductVersionsInfo = fetchProductVersions(productListData)

  return (
    <>
      <Head>
      {pageTitle &&
        <title>{pageTitle}</title>
      }
        <meta
          name="description"
          key="description"
          content="The API Dev Portal provides comprehensive documentation for use by API developers."
          />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className={styles.desktopNavBar}>
        <section className={styles.sectionRow}>
          <USBGrid
            alignItems={defaultGridOptions.alignItems}
            columnCount={defaultGridOptions.columnCount}
            justifyContent={defaultGridOptions.justifyContent}
            display={defaultGridOptions.display}
            padding={defaultGridOptions.padding}
            addClasses={fullWidthLayout ? styles.gridWrapperFullWidth : styles.gridWrapper}
          >
            {/* Desktop Menu */}
            <DesktopNavBar
              type={type}
              menuItems={menuItems}
              imageURL={imageURL}
              mainmenuId={mainmenuId}
              fullWidthLayout={fullWidthLayout}
              regionOptions={regionOptions}
              isLoginSignUpDisabled={headerData.isLoginSignUpDisabled}
              isSearchDisabled={headerData.isSearchDisabled}
              allProductVersionsInfo={allProductVersionsInfo}
              authWidgetConfigData={headerData.authWidgetConfigData}
            ></DesktopNavBar>
          </USBGrid>
        </section>
      </div>
      <div className={styles.mobileNavBar}>
        <section className={styles.sectionRow}>
          <USBGrid
            gridGap={defaultGridOptions.gridGap}
            alignItems={defaultGridOptions.alignItems}
            columnCount={defaultGridOptions.columnCount}
            justifyContent={defaultGridOptions.justifyContent}
            display={defaultGridOptions.display}
            padding={headerDefaultGridOptions.padding}
          >
            {/* Mobile Menu */}
            <MobileNavBar
              type={type}
              menuItems={menuItems}
              imageURL={imageURL}
              mainmenuId={mainmenuId}
              regionOptions={regionOptions}
              isLoginSignUpDisabled={headerData.isLoginSignUpDisabled}
              isSearchDisabled={headerData.isSearchDisabled}
              allProductVersionsInfo={allProductVersionsInfo}
              authWidgetConfigData={headerData.authWidgetConfigData}
            ></MobileNavBar>
          </USBGrid>
        </section>
      </div>
    </>
  )
}

