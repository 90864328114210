export const fetchAccessibleProductLink = (
    version: any,
    productSlug: any,
    allProductVersionsInfo: any,
    domainKey: any
  ) => {
    // iterate through the provided products array to find latest eligible version for each product
    for (const key in allProductVersionsInfo['restrictedPrivateProductAllVersion'][productSlug]) {
      if (allProductVersionsInfo['restrictedPrivateProductAllVersion'][productSlug].hasOwnProperty(key) && key == domainKey) {
        const restrictedProductInfo = allProductVersionsInfo['restrictedPrivateProductAllVersion'][productSlug][domainKey]
        let sortedDisplayList = Object.keys(restrictedProductInfo).sort().reverse()
        for (let sdlKey in sortedDisplayList) {
          let thisVersion = sortedDisplayList[sdlKey]
          // Check if user logged in
          if(Object.keys(allProductVersionsInfo['userSessionInfo']).length > 0) {
            if(allProductVersionsInfo['restrictedPrivateProductAllVersion'][productSlug][domainKey][thisVersion] != undefined) {
              const usiRestrictedPrivateProducts = allProductVersionsInfo['userSessionInfo'];
              for(const uKey in usiRestrictedPrivateProducts) {
                if(usiRestrictedPrivateProducts.hasOwnProperty(uKey)) {
                  for(const dKey in usiRestrictedPrivateProducts[uKey][productSlug]) {
                    if(usiRestrictedPrivateProducts[uKey][productSlug][domainKey].includes(thisVersion) && dKey == domainKey) {
                      return allProductVersionsInfo['restrictedPrivateProductAllVersion'][productSlug][domainKey][thisVersion]
                    }
                  }
                }
              }
            }
          } else {
            for (const key in allProductVersionsInfo['nonRestrictedPrivateProductAllVersion'][productSlug]) {
              if (allProductVersionsInfo['nonRestrictedPrivateProductAllVersion'][productSlug].hasOwnProperty(key) && key == domainKey) {
                const nonRestrictedProductInfo = allProductVersionsInfo['nonRestrictedPrivateProductAllVersion'][productSlug][domainKey]
                let sortedDisplayList = Object.keys(nonRestrictedProductInfo).sort().reverse()
                for (let sdlKey in sortedDisplayList) {
                  let thisVersion = sortedDisplayList[sdlKey]
                  if (Object.keys(allProductVersionsInfo['nonRestrictedPrivateProductAllVersion'][productSlug][domainKey]).length > 0) {
                    return allProductVersionsInfo['nonRestrictedPrivateProductAllVersion'][productSlug][domainKey][thisVersion]
                  }
                }
              }
            }
          }
        }
      }
    }
    for (const key in allProductVersionsInfo['nonRestrictedPrivateProductAllVersion'][productSlug]) {
    if (allProductVersionsInfo['nonRestrictedPrivateProductAllVersion'][productSlug].hasOwnProperty(key) && key == domainKey) {
      const nonRestrictedProductInfo = allProductVersionsInfo['nonRestrictedPrivateProductAllVersion'][productSlug][domainKey]
      let sortedDisplayList = Object.keys(nonRestrictedProductInfo).sort().reverse()
      for (let sdlKey in sortedDisplayList) {
        let thisVersion = sortedDisplayList[sdlKey]
        if(Object.keys(allProductVersionsInfo['nonRestrictedPrivateProductAllVersion'][productSlug][domainKey]).length > 0) {
          return allProductVersionsInfo['nonRestrictedPrivateProductAllVersion'][productSlug][domainKey][thisVersion]
        } else {
          return false
        }
      }
    }
  }

  return false
}