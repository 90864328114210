import { MyUser } from '@/modules/UserManagement/components/UserList/userList.model'


export const orderByNameLogic = (currentObj: MyUser, nextObj: MyUser) => {
    if (currentObj?.firstName?.toLowerCase() < nextObj?.firstName?.toLowerCase()) {
      return -1;
    }
    if (currentObj?.firstName?.toLowerCase() > nextObj?.firstName?.toLowerCase()) {
      return 1;
    }
    return 0;
   }

export const orderByDateLogic = (currentObj: MyUser, nextObj: MyUser) => {
    if (currentObj?.deleteRequest !== null) {
      if (Number(new Date(currentObj?.updatedAt)) > Number(new Date(nextObj?.updatedAt))) {
        return -1;
      }
      if (Number(new Date(currentObj?.updatedAt)) < Number(new Date(nextObj?.updatedAt))) {
        return 1;
      }
      return 0;
    }
    return 0;
   }

export const orderByPendingDeleteLogic = (currentObj: MyUser, nextObj: MyUser) => {
    if (currentObj?.deleteRequest !== null && nextObj?.deleteRequest !== null) {
       return 1;
    } 
    if (currentObj?.deleteRequest !== null) {
      return -1
    }
      return 0;
   }

 
