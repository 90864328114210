import { MyUser, UpdatedUser } from '@/modules/UserManagement/components/UserList/userList.model'
import { useQueryClient } from '@tanstack/react-query'
import { orderByNameLogic, orderByPendingDeleteLogic, orderByDateLogic } from '@/utils/user-management.sorting'

export const USERS_QUERY_KEY = 'LIST-OF-VERIFIED-ORG-USERS'

export const NON_VERIFIED_USERS_QUERY_KEY = 'LIST-OF-NON-VERIFIED-USERS'

export const USER_QUERY_KEY = 'USER-DATA'


export const useUserManagementCacheUtil = () => {
  const queryClient = useQueryClient()

  const addUserInCache = (newUser: any) => {
    queryClient.setQueryData(
      [USERS_QUERY_KEY],
      (
        currentUsersList: MyUser[] | undefined
      ): any => {
        const newUserData = newUser?.data?.data?.addDeveloper
        if (newUserData && newUserData !== null) {
          const finalNewUserData: MyUser = {
            createdAt: new Date().toISOString(),
            deleteRequest: null,
            email: newUserData?.email,
            firstName: newUserData?.firstName,
            lastName: newUserData?.lastName,
            username: newUserData?.username,
            fullName: newUserData?.firstName + ' ' + newUserData?.lastName,
            id: newUserData?.id,
            lastLoginAt: null,
            updatedAt: new Date().toISOString()
          }
          return currentUsersList ? [...currentUsersList, finalNewUserData].sort(orderByNameLogic).sort(orderByPendingDeleteLogic).sort(orderByDateLogic) : [finalNewUserData]
        }
    }
    )
  }


  const updateUserInListInCache = (updatedUser:any) => {
    queryClient.setQueryData(
      [USERS_QUERY_KEY],
      (
        currentUsersList: UpdatedUser[] | undefined
      ): UpdatedUser[] => {
        const updatedUserId = updatedUser?.data?.data?.updateDeveloper?.data?.id
        const updatedUserDetails = updatedUser?.data?.data?.updateDeveloper?.data

        const updatedUserFullName = updatedUserDetails?.firstName + ' ' + updatedUserDetails?.lastName
        let finaluserUpdatedList: UpdatedUser[] = [];
        if (currentUsersList) {
          finaluserUpdatedList = currentUsersList.map((user: UpdatedUser) => {

            const finaluserUpdatedListwithFullName = { ...updatedUserDetails, fullName: updatedUserFullName }
            return user.id === updatedUserId
              ? finaluserUpdatedListwithFullName
              : user

          })
        }

        return finaluserUpdatedList

      }
    )
  }
  // self update fetch list by id update by edited details
  const updateUserInCache = (updatedUser:any) => {
    queryClient.setQueryData(
      [USER_QUERY_KEY],
      (
        currentUser: UpdatedUser | undefined
      ): UpdatedUser | undefined => {
        const updatedUserId = updatedUser?.data?.data?.updateDeveloper?.data?.id
        const updatedUserDetails = updatedUser?.data?.data?.updateDeveloper?.data
        const updatedUserFullName = updatedUserDetails?.firstName + ' ' + updatedUserDetails?.lastName
        if (currentUser && currentUser.id  === updatedUserId) {
          currentUser =  {...currentUser, firstName: updatedUserDetails?.firstName, lastName: updatedUserDetails?.lastName, fullName: updatedUserFullName}
        }
        return currentUser;
      }
    )
  }


  const softDeleteUserRequestInCache = (id: string) => {
    queryClient.setQueryData(
      [USERS_QUERY_KEY],
      (
        currentUsersList: MyUser[] | undefined
      ): MyUser[] => {
        let finalList: any = [];
        if (currentUsersList && currentUsersList.length) {
          finalList = currentUsersList.map((res: any) => {
            if (res.id === id) {
              res!.updatedAt = new Date().toISOString()
              res!.deleteRequest = {
                causerId: id,
                causerName: res?.fullName,
                deleteRequestAt: new Date().toISOString(),
                reason: "Soft Delete User"
              }
            }
            return res;
          }).sort(orderByNameLogic).sort(orderByPendingDeleteLogic).sort(orderByDateLogic);
        }
        return finalList;
      }
    )
  }

  const denyAccessRequestInCache = (id: string) => {
    queryClient.setQueryData(
      [USERS_QUERY_KEY],
      (
        currentUsersList: MyUser[] | undefined
      ): MyUser[] => {
        let finalList: any = [];
        if (currentUsersList && currentUsersList.length) {
          finalList = currentUsersList.map((res: any) => {
            if (res.id === id) {
              res!.updatedAt = new Date().toISOString()
              res!.deniedBy = {
                mainContactId: id,
                declinedAt: new Date().toISOString()
              }
            }
            return res;
          }).sort(orderByNameLogic).sort(orderByPendingDeleteLogic).sort(orderByDateLogic);
        }
        return finalList;
      }
    )
  }

  const invalidateAddActivityLogInCache = () => {
    queryClient.invalidateQueries({ queryKey: ['ADD-ACTIVITY-LOG'] })
  }

  return {
    addUserInCache,
    updateUserInCache,
    softDeleteUserRequestInCache,
    updateUserInListInCache,
    denyAccessRequestInCache,
    invalidateAddActivityLogInCache
  }
}
