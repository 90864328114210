import { useEffect, useRef, useState } from 'react'
import MicroAppLoader from '@usb-ui-tools/micro-app-loader'
import getCorrelationId from '@/utils/correlation-id/correlation-id.util'
import { AuthWidgetConfigData } from '@/modules/challenge-widget/challengeWidget.model'
import { useUserManagementMutation } from '@/modules/UserManagement/services/user-management.mutation'

export const ChallengeWidget = ({
  onAuthSuccess,
  onAuthFailure,
  authWidgetConfigData = null,
  activityName = '',
}: {
  onAuthSuccess?: any
  onAuthFailure?: any
  authWidgetConfigData: AuthWidgetConfigData | null
  activityName: string
}) => {
  const correlationId = getCorrelationId
  const [challengeWidgetNamespace, setchallengeWidgetNamspace] =
    useState<string>('')
  let userId
  const { addActivityLogMutation } = useUserManagementMutation()
  const [transmitURL, settransmitURL] = useState<string>('')

  useEffect(() => {
    const hostname = window.location.host || ''
    let transmitConfigURL: any =
      authWidgetConfigData?.MFA_WIDGET_CONFIG_TRANSMIT_URL || ''
    transmitConfigURL = /localhost|bank-dns.com/.test(hostname)
      ? transmitConfigURL
      : authWidgetConfigData?.MFA_WIDGET_CONFIG_TRANSMIT_PROXY_URL
    settransmitURL(transmitConfigURL)
    setchallengeWidgetNamspace('USBAuthChallenge')
    userId = sessionStorage?.getItem('userId')

    //activity log
    try {
      if (activityName) {
        const userName = sessionStorage?.getItem('userId')
        activityName = activityName[0].toUpperCase() + activityName.slice(1) // first letter capitalized
        const activityLogData = {
          action: 'Stepup',
          message: `Initiated step-up authentication to ${activityName}. Username: ${userName}. SiteId: ${authWidgetConfigData?.SITE_ID}.`,
        }
        addActivityLogMutation.mutate(
          { data: activityLogData },
          {
            onSettled(data: any | undefined, error: any) {
              if (error) {
                console.log('Error in adding activity log for stepup')
              }
            },
          }
        )
      }
    } catch (err: any) {
      console.log('Error in adding activity log for stepup')
    }
  }, [])

  let header, configSettings
  if (authWidgetConfigData) {
    // Config API Headers
    header = {
      'Tenant-ID': 'USB',
      'App-ID': 'DevPortal',
      'Channel-ID': 'web',
      'App-Version': '1',
      AK: authWidgetConfigData.MFA_WIDGET_HEADER_AK,
      'Correlation-ID': correlationId, // Random GUID
    }

    // Local Config Settings
    configSettings = {
      displayFields: {
        DISPLAY_GET_LOGIN_ASSISTANCE: 'hide',
      },
      errorMessages: {
        USER_LOCKED_NO_LAS:
          'We had to lock your account after too many attempts.',
      },
      transmitConfigs: {
        URL: transmitURL,
        APP_ID: 'devportal_web',
        policyIds: {
          POLICY: 'devportal', //pass the policy name here
        },
        policyParams: {
          appId: 'DevPortal',
          tenantId: 'USB',
          channelId: 'web',
          appVersion: '1',
          routingKey: authWidgetConfigData.MFA_WIDGET_CONFIG_ROUTING_KEY,
          correlationId: correlationId, // Random GUID
          organizationType: authWidgetConfigData.FROM_EMAIL_BRAND,
          fromAddressLabel: authWidgetConfigData.FROM_EMAIL_LABEL,
          fromAddress: authWidgetConfigData.FROM_EMAIL,
        },
      },
    }
  }

  return (
    <>
      {authWidgetConfigData && (
        <MicroAppLoader
          elementRef={useRef(null)}
          id="auth-challenge"
          manifestPath={authWidgetConfigData.MFA_WIDGET_CHALLENGE_MANIFEST_PATH}
          isDomainPrefixedToManifestPath={false}
          namespace={challengeWidgetNamespace}
          appProps={{
            userName: userId,
            configSettings: configSettings,
            onTransmitAuthorizationFailure: onAuthFailure,
            onTransmitAuthorizationSuccess: onAuthSuccess,
            onTransmitAuthorizationAnonSuccess: onAuthSuccess,
            onTransmitAuthorizationAnonFailure: onAuthFailure,
            configApiHeaders: header,
          }}
        />
      )}
    </>
  )
}
