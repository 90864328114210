//Shield components
import { useRouter } from 'next/router'
import { getSession, signIn, SignInResponse } from 'next-auth/react'
import React, { useEffect, useState } from 'react'
import { loginPageData as pageData } from '@/components/Login/login.content'
import { showSuccessToast } from '@/utils/toast/toast'
import parse from 'html-react-parser'
import * as Tealium from '@/modules/tealium/Tealium'
import getCorrelationId from '@/utils/correlation-id/correlation-id.util'
import AppLoader from '@usb-ui-tools/micro-app-loader'
import { mfaJWTValidation } from './services/login-service'
import ChallengeWidgetModal from '@/modules/challenge-widget/ChallengeWidget'
import { MFAWidgetProps } from './loginWidget.model'
import { AuthWidgetConfigData } from '@/modules/challenge-widget/challengeWidget.model'
import ReactLoading from 'react-loading'
import { USBGrid, USBColumn } from '@usb-shield/react-grid'
import { twelveColsLoadingSpinnerOption } from '@/utils/sign-up-grid.util'
import loginWidgetStyles from '@/components/LoginWidget/loginWidget.module.scss'
import { useUserManagementMutation } from '@/modules/UserManagement/services/user-management.mutation'
import { setLastStepupTime } from '@/utils/auth-widget/auth-widget'

export const LoginWidget = ({
  overlayDarkModeClass,
  loginModal = false,
  authWidgetConfigData = null,
  loginWidgetCallback = null,
  mfaWidgetProps = null,
}: {
  overlayDarkModeClass?: string
  loginModal?: boolean
  authWidgetConfigData: AuthWidgetConfigData | null
  loginWidgetCallback: any
  mfaWidgetProps?: MFAWidgetProps | null
}) => {
  const correlationId = getCorrelationId
  const router = useRouter()
  const { addActivityLogMutation } = useUserManagementMutation()
  const [loginWidgetNameSpace, setLoginWidgetNameSpace] = useState<string>('')
  const [transmitURL, settransmitURL] = useState<string>('')

  // MFA Challenge Widget
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [showSpinner, setShowSpinner] = useState<boolean>(true)
  const [mfaCallBackUrl, setMFACallBackUrl] = useState<string>('')
  const [activityName, setActivityName] = useState<string>('')
  useEffect(() => {}, [modalIsOpen, mfaCallBackUrl])

  const showChallengeWidget = (url: string, activityName: string) => {
    setActivityName(activityName)
    setMFACallBackUrl(url)
    setModalIsOpen(true)
  }

  // End MFA Challenge Widget

  const loginSuccessHandler = async () => {
    const sessionData = await getSession()
    //setting up stepup initial time is '0' seconds in session storage
    setLastStepupTime(0)
    //Hide overlay for model
    if (overlayDarkModeClass) {
      const overlay: any = document.querySelector<HTMLElement>(`.showOverlay`)

      if (overlay) {
        overlay.classList.remove(overlayDarkModeClass)
      }
    }

    /* NOTE: 
    callback url functionality is required in the future when the user enters a protected page 
    and is redirected to the login page for authentication, then redirected back to the protected page
    using the callback key stored in router.query 
    */
    // return false
    if (router.asPath.includes('/search?searchTermQuery=')) {
      router.push(router.asPath + 'userLoggedIn', router.asPath)
    } else {
      const path = router.asPath.split('=')
      const callBackUrl: string | undefined = path.length ? path[1] : ''
      if (callBackUrl == '/dashboard/usage') {
        if (mfaWidgetProps == null) showChallengeWidget(callBackUrl, 'Usage')
        else {
          mfaWidgetProps.setChallengeModalIsOpen(true)
          mfaWidgetProps.setCallBackUrl(callBackUrl)
        }
      } else if (sessionData?.decodedAccessToken?.isTOUAccepted === false) {
        router.push('/accept-terms-of-use')
      } else if (process.env.FEATURE_DEVELOPER_DASHBOARD === 'false') {
        router.push(callBackUrl ? decodeURIComponent(callBackUrl) : '/')
      } else {
        router.push(
          callBackUrl ? decodeURIComponent(callBackUrl) : '/dashboard'
        )
      }
    }

    showSuccessToast(pageData.loginPage.successMsg)
    Tealium.loginFormSuccessfulDelivery()
  }

  useEffect(() => {
    window.process = {}
    process.env.LOG_LEVEL = 'info'
    const hostname = window.location.host || ''
    let transmitConfigURL: any = authWidgetConfigData?.MFA_WIDGET_CONFIG_TRANSMIT_URL || ''
    transmitConfigURL = /localhost|bank-dns.com/.test(hostname) ? transmitConfigURL : authWidgetConfigData?.MFA_WIDGET_CONFIG_TRANSMIT_PROXY_URL
    settransmitURL(transmitConfigURL)
    setLoginWidgetNameSpace('USBAuthLoginModule')
  }, [])

  let configSettings,
    header,
    configAPIURL: string | null = ''
  if (authWidgetConfigData) {
    header = {
      'Tenant-ID': 'USB',
      'App-ID': 'DevPortal',
      'Channel-ID': 'web',
      'App-Version': '1',
      AK: authWidgetConfigData.MFA_WIDGET_HEADER_AK,
      'Correlation-ID': correlationId,
    }

    configSettings = {
      displayFields: {
        ACCOUNT_TYPE_DROPDOWN: 'hide',
        LOGIN_AS_GUEST_BUTTON: 'hide',
        LOGIN_HELP: 'hide',
        DISPLAY_GET_LOGIN_ASSISTANCE: 'hide',
        FORGOT_ID: 'show',
        FORGOT_PASSWORD: 'show',
      },
      regex: {
        USER_ID: '(.*?)',
        PASSWORD: '(.*?)',
      },
      transmitConfigs: {
        URL: transmitURL,
        APP_ID: 'devportal_web',
        policyIds: {
          POLICY: 'login',
        },
        policyParams: {
          appId: 'DevPortal',
          tenantId: 'USB',
          channelId: 'web',
          appVersion: '1',
          routingKey: authWidgetConfigData.MFA_WIDGET_CONFIG_ROUTING_KEY,
          applicationId: 'DevPortal',
          useCase: 'login',
          XProfileDataRequested: false,
          correlationId: correlationId,
        },
      },
      urls: {
        JS_LOGGER_DOMAIN:
          authWidgetConfigData.MFA_WIDGET_CONFIG_JS_LOGGER_DOMAIN,
        loginAssistance: {
          CREATE_USERID_PASSWORD: pageData.loginPage.form.signUp.link,
          RETRIVE_ID: pageData.loginPage.form.forgotUsername.link,
          RESET_PASSWORD: pageData.loginPage.form.forgotPassword.link,
        },
      },
      lang: {
        'en-us': {
          labels: {
            HEADER_LABEL: pageData.loginPage.form.login.label,
            CREATE_USERNAME_PASSWORD: pageData.loginPage.form.signUp.label,
            FORGOT_ID_LINK_LABEL: pageData.loginPage.form.forgotUsername.label,
            FORGOT_PASSWORD_LINK_LABEL:
              pageData.loginPage.form.forgotPassword.label,
            RESET_PASSWORD_LINK_LABEL: '',
          },
          // errorMessages: {
          //   USER_LOCKED: pageData.loginPage.errorMsgs.generalErrorMsg,
          //   TRANSMIT_LOCKED: pageData.loginPage.errorMsgs.generalErrorMsg,
          //   TRANSMIT_LOCKED_CONTINUE_MSG:
          //     pageData.loginPage.errorMsgs.generalErrorMsg,
          // },
        },
      },
    }
    configAPIURL = authWidgetConfigData.MFA_WIDGET_LOGIN_CONFIG_API_URL
  }

  const successCallback = async (res: any) => {
    loginWidgetCallback(null, '')
    if (res.token && res.token != '') {
      let username = JSON.parse(window.atob(res.token.split('.')[1])).sub
      const mfaJWTValidationResult = await mfaJWTValidation(username, res.token)
      if (mfaJWTValidationResult) {
        const signInResult: SignInResponse | undefined = await signIn(
          'credentials',
          {
            redirect: false,
            username: username,
          }
        )
        if (signInResult?.ok) {
          await loginSuccessHandler()
        }

        if (signInResult?.error) {
          const loginErrorNotification: any = parse(signInResult?.error)
          loginWidgetCallback(false, loginErrorNotification)
          loginWidgetCallback(true, loginErrorNotification)
        }
      } else {
        const loginErrorNotification: any = 'Something went wrong'
        loginWidgetCallback(false, loginErrorNotification)
        loginWidgetCallback(true, loginErrorNotification)
      }
    }
  }

  const failureCallback = async (res: any) => {
    console.log('onAuthFailure -> Failure', res)
    loginWidgetCallback(true, '')
  }

  let appNameForSiteCat = ''
  let uxNameForSiteCat = ''
  let clientNameForSiteCat = ''
  let appDynamicsKey = ''

  const onLoginModuleLoaded = async () => {
    loginWidgetCallback(true, '', true)
    setShowSpinner(false)
  }

  return (
    <div className={loginWidgetStyles.loginWidgetContainer}>
      {showSpinner && (
        <USBGrid
          padding="normal"
          dataTestId="login-loader"
          addClasses={loginWidgetStyles.widgetLoader}
        >
          <USBColumn layoutOpts={twelveColsLoadingSpinnerOption}>
            <ReactLoading
              type={'spokes'}
              color={'rgb(12 32 116)'}
              height={70}
              width={70}
            />
          </USBColumn>
        </USBGrid>
      )}
      <div
        className={
          showSpinner
            ? loginWidgetStyles.disableWidget
            : loginWidgetStyles.enableWidget
        }
      >
        {authWidgetConfigData && (
          <AppLoader
            elementRef={React.createRef()}
            id={`auth-login${Number(loginModal)}`}
            isDomainPrefixedToManifestPath={false}
            manifestPath={authWidgetConfigData.MFA_WIDGET_LOGIN_MANIFEST_PATH}
            namespace={loginWidgetNameSpace}
            appProps={{
              onLoginModuleLoaded: onLoginModuleLoaded,
              configApiHeaders: header,
              configSettings: configSettings,
              onAuthorizationSuccess: successCallback,
              onAuthorizationFailure: failureCallback,
              appNameForSiteCat: appNameForSiteCat,
              uxNameForSiteCat: uxNameForSiteCat,
              clientNameForSiteCat: clientNameForSiteCat,
              isOLB: false,
              isAppDEnabled: false,
              isReportingEnabled: false,
              isIovationEnabled: false,
              isJSLoggerEnabled: true,
              configApiURL: configAPIURL,
              appDKey: appDynamicsKey,
              isNewLASUrl: true,
              isShieldMFAEnabled: true,
              isHeaderRequired: false,
              isFooterRequired: false,
            }}
          />
        )}
      </div>
      {/* render Challenge widget modal */}
      {modalIsOpen && authWidgetConfigData && (
        <ChallengeWidgetModal
          callBackUrl={mfaCallBackUrl}
          challengeModalIsOpen={modalIsOpen}
          setChallengeModalIsOpen={setModalIsOpen}
          authWidgetConfigData={authWidgetConfigData}
          activityName={activityName}
        ></ChallengeWidgetModal>
      )}
    </div>
  )
}

declare global {
  interface Window {
    process?: any
  }
}
